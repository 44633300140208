*::-webkit-scrollbar {
  display: none !important;
}

.backdrop {
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9;
}

.modal {
  z-index: 10;
  position: fixed;
  top: 108px;
  bottom: 108px;
  width: 40rem;
  left: calc(50% - 20rem);
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  animation: slide-down 300ms ease-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 801px) and (max-width: 1280px) {
  .modal {
    top: 100px;
    bottom: 100px;
    width: 40rem;
    left: calc(50% - 20rem);
    overflow-y: scroll;
  }
}

@media screen and (min-width: 476px) and (max-width: 800px) {
  .modal {
    top: 100px;
    bottom: 100px;
    width: calc(100% - 100px);
    left: 50px;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 475px) {
  .modal {
    top: 50px;
    bottom: 50px;
    width: calc(100% - 44px);
    left: 22px;
  }
}
